import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';



@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(private firebase: AngularFireAuth) {}

  signin(formData: any) {
    return new Promise((resolve, reject) => {
      this.firebase.auth.signInWithEmailAndPassword(formData.email, formData.password).then((re) => {
        resolve(re.user);
      }).catch(function (error) {
        reject(error)
      });
    });
  }

  logout() {
    return new Promise((resolve) => {
      this.firebase.auth.signOut().then(()=>{
        resolve({ok: true});
      }).catch(()=>{
        resolve({ok: false});
      });
    });
  }

  getIDToken() {
    return new Promise((resolve, reject)=>{
      this.firebase.idToken.subscribe((token)=>{
        resolve(token);
      });
    });
  }


  // inviteUser(email) {
  //   return new Promise((resolve)=>{
  //     const actionCodeSettings: auth.ActionCodeSettings = {
  //       // URL you want to redirect back to. The domain (www.example.com) for
  //       // this URL must be whitelisted in the Firebase Console.
  //       url: 'http://localhost:4200',
  //       // This must be true for email link sign-in.
  //       handleCodeInApp: true
  //     };


  //     this.firebase.auth.sendSignInLinkToEmail(email, actionCodeSettings).then((re)=>{
  //       console.log(re);
  //     }).catch((err)=>{
  //       console.log(err);
  //     });

  //     resolve(null);
  //   });
  // }
}
