// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serverEndpoint: "https://speech.englishmobile.com:3000/",
  // serverEndpoint: "http://localhost:3000/",
  firebaseConfig: {
    apiKey: "AIzaSyDZI5HttJcIl6AlMQ1mLLusYcNERjKvjWU",
    authDomain: "emo20-dashboard.firebaseapp.com",
    projectId: "emo20-dashboard",
    storageBucket: "emo20-dashboard.appspot.com",
    messagingSenderId: "71805942618",
    appId: "1:71805942618:web:7adaf6daf26d7b73d3d163"

    
    // databaseURL: "https://emo20-247717.firebaseio.com",
    // measurementId: "G-V61YS457DD"
  },

  tutorLanguage: 'es'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
